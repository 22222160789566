import React, { useState } from "react";
import axios from "axios";
import Footer from "./Footer";
import AddNav from "./Navbar/AddNav";
import { useNavigate } from "react-router-dom";

const Resetpassword = (props) => {
  const [password, setPassword] = useState();
  const [newpassword, setNewpassword] = useState();
  const navigate =useNavigate();

  const Updatepassword = () => {
    var id = sessionStorage.getItem("id");

    axios
    
      .put(`${process.env.REACT_APP_NODE_URL}/resetPassword/resetPassword`, {
        password: password,
        newpassword: newpassword,
        id: id,
      })
      .then((response) => {
        if (response) {
            console.log(response.data)

          alert(
            "Request Updated!",
            "You are successfully Reset your password!",
            "success"
          );
          }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <>
    <AddNav/>
      <div className="container">
        {/* <form> */}
          <div className="row" style={{ minHeight: "100vh" }}>
            <div className="col-2"></div>
            <div className="col-md-8 col-sm-12">
              <h2 className="text-center mt-5 mb-5 text-color head-color">Reset Password</h2>
              <div className="form-group">
                <div className="row">
                  <div className="col-md-3 col-sm-12"></div>
                  <div className="col-md-6 col-sm-12 ">
                    {" "}
                    <label htmlFor="password">Enter your Old Password</label>
                  <input
                  type="text"
                  className="form-control mb-5"
                  name="password"
                  onChange={(event) => setPassword(event.target.value)}
                />
                    
                  </div>
                  <div className="col-md-6 col-sm-12"></div>
                </div>
                <div className="row">
                   <div className="col-md-3 col-sm-12"></div>
                  <div className="col-md-6 col-sm-12">
                  <label htmlFor="newpassword">Enter your new Password</label>
                <input
                  type="text"
                  className="form-control mb-5"
                  name="newpassword"
                  onChange={(event) => setNewpassword(event.target.value)}
                />
                  </div>
                  <div className="col-md-6 col-sm-12"></div>
                </div>

                
                <div className="row">
                   <div className="col-md-3 col-sm-12"></div>
                  <div className="col-md-3 col-sm-12 text-start">
                  <button  onClick={() => {
                          Updatepassword();
                        }} className="btn btn-md btn-primary  mb-3 ">Update</button>
                  </div>
                  <div className="col-md-6 col-sm-12">
                  <a  onClick={() => {
                        navigate("/login");
                      }} className=" text-left ">Back to login</a>

  
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-2 col-sm-12"></div>
          </div>
        {/* </form> */}
      </div>
      <Footer/>
      </>
    
  );
};

export default Resetpassword;
