import { Link } from "react-router-dom";
import bg from "../../Assets/Image/usht.png"
import {Nav, Navbar, Container} from 'react-bootstrap';  
function Topnav() {
  const url="https://cdn.shopify.com/s/files/1/0418/2920/4118/files/uritox_116edecf-e9e5-4daf-bd19-7aee651a9889_540x.png?v=1621346742"

  return (

    <>

<Navbar className="bg-color" expand="md">  
    <Container>  
      
      <Navbar.Toggle className="link-color" aria-controls="basic-navbar-nav" />  
      <Navbar.Collapse id="basic-navbar-nav">  
        <Nav className="ms-auto">  
          <Nav.Link className="link-color" href="/registration">Add/Claim Your Business</Nav.Link>  
          <Nav.Link className="link-color text-end" href="/login">SignIn</Nav.Link>  
          
        </Nav>  
        
      </Navbar.Collapse>  
    </Container>  
    
  </Navbar>  

  <Navbar className="justify-content-center menu">  

    <Navbar.Brand className="mb-5 mt-5">          
      <img src={url} alt="Image Description" width={300} />
</Navbar.Brand>  
</Navbar>  

    </>
  );
}
export default Topnav;
