import React from 'react'
import Footer from "./Footer";
import SideNav from "./Navbar/SideNav"
import Nav from "./Navbar/Nav"
import { Outlet } from "react-router-dom";


function Dashboard() {
  return (
    <>
      <Nav />

      <div className="container-fluid left-container">
        <div className="row h-90 ">
          <div className="col-2">
            <SideNav />
          </div>
          <div className="col-10">
            <Outlet />
          </div>
        </div>
      </div>
      <Footer />
    </>
             )
}

export default Dashboard;