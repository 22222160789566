import { Link } from "react-router-dom";
// import bg from "../Assets/Image/usht.png"
function AddNav() {
  return (
    <div className="container-fluid">
      <div className="row bg-color pt-3 pb-3">
      {/* <div className="col-2"></div> */}

        <div className="col-10 text-left"></div>
        <div className="col-md-2 col-sm-12 text-end">
          <Link className="link-color" to="/registration">
            Add/Claim Your Business {" "}
          </Link>
        </div>
      </div>
    </div>
  );
}
export default AddNav;
