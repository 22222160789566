import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

function Edit() {
  const { id } = useParams();

  const [activeUser, setActiveUser] = useState({});
  //  const [FieldValues, setFieldValues] = useState();

  const GetUser = async () => {
    console.log(id);
    await axios
      .get(`${process.env.REACT_APP_NODE_URL}/getuser/getuser/`+id)
      .then((response) => {
        setActiveUser(response.data[0]);
        console.log(response.data, "response");
        console.log(id);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const HandleFieldsValue = (key, value) => {
    const field = { ...activeUser };
    console.log(key, value);
    field[key] = value;
    console.log(field, "field");
    setActiveUser(field);
  };
  const UpdateUser = async () => {
    await axios
      .put(
        `${process.env.REACT_APP_NODE_URL}/updateuser/updateuser/`+id,
        activeUser
      )
      .then((response) => {
        setActiveUser(response.data);
        console.log(response.data);
        alert("updated Sucessfully");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    GetUser();
  }, []);
  return (
    <>
      <div className="container">
        {/* {activeUser.map((activeUser, index) => ( */}
        <>
          {/* <form> */}
          <div className="row" style={{ minHeight: "100vh" }}>
            <div className="col-2"></div>
            <div className="col-8">
              <h2 className="text-center mt-5 mb-5 text-color head-color">
                Edit Your Business Profile
              </h2>
              <div className="form-group">
                <label className="form-label float-start">Business Name:</label>
                <input
                  className="form-control"
                  placeholder="Enter your Business Name"
                  type="text"
                  name="Business_name"
                  id="bname"
                  required="true"
                  defaultValue={
                    activeUser.Business_name ? activeUser.Business_name : ""
                  }
                  onChange={(event) => {
                    HandleFieldsValue(event.target.name, event.target.value);
                  }}
                />
              </div>
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <div className="form-group">
                    <label className="form-label float-start mt-3">
                      Address Line 1:
                    </label>
                    <input
                      className="form-control"
                      placeholder="Enter your Address"
                      type="text"
                      name="Address1"
                      id="al1"
                      required="true"
                      defaultValue={
                        activeUser.Address1 ? activeUser.Address1 : ""
                      }
                      onChange={(event) => {
                        HandleFieldsValue(
                          event.target.name,
                          event.target.value
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-6 col-sm-12">
                  <div className="form-group">
                    <label className="form-label float-start mt-3">
                      Address Line 2:
                    </label>
                    <input
                      className="form-control"
                      placeholder="Enter your Address"
                      type="text"
                      name="Address2"
                      id="al2"
                      defaultValue={
                        activeUser.Address2 ? activeUser.Address2 : ""
                      }
                      onChange={(event) => {
                        HandleFieldsValue(
                          event.target.name,
                          event.target.value
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <div className="form-group">
                    <label className="form-label float-start mt-3">City:</label>
                    <input
                      className="form-control"
                      placeholder="Enter your city"
                      type="text"
                      name="City"
                      id="city"
                      required="true"
                      defaultValue={activeUser.City ? activeUser.City : ""}
                      onChange={(event) => {
                        HandleFieldsValue(
                          event.target.name,
                          event.target.value
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-6 col-sm-12">
                  <div className="form-group">
                    <label className="form-label float-start mt-3">State</label>
                    <input
                      className="form-control"
                      placeholder="Enter your state"
                      type="text"
                      name="State"
                      id="state"
                      required="true"
                      defaultValue={activeUser.State ? activeUser.State : ""}
                      onChange={(event) => {
                        HandleFieldsValue(
                          event.target.name,
                          event.target.value
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <div className="form-group">
                    <label className="form-label float-start mt-3">
                      Country:
                    </label>
                    <input
                      className="form-control"
                      placeholder="Enter your Country"
                      type="text"
                      name="Country"
                      id="country"
                      required="true"
                      defaultValue={
                        activeUser.Country ? activeUser.Country : ""
                      }
                      onChange={(event) => {
                        HandleFieldsValue(
                          event.target.name,
                          event.target.value
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-6 col-sm-12">
                  <div className="form-group">
                    <label className="form-label float-start mt-3">
                      Postal Code:
                    </label>
                    <input
                      type="text"
                      name="Postal_code"
                      className="form-control"
                      placeholder="Postal code"
                      pattern="[0-9]*"
                      required="true"
                      defaultValue={
                        activeUser.Postal_code ? activeUser.Postal_code : ""
                      }
                      onChange={(event) => {
                        HandleFieldsValue(
                          event.target.name,
                          event.target.value
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <div className="form-group">
                    <label className="form-label float-start mt-3">
                      Contact No.
                    </label>
                    <input
                      type="tel"
                      id="phone"
                      className="form-control"
                      name="Contact_Number"
                      placeholder="123-45-678"
                      pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                      required="true"
                      defaultValue={
                        activeUser.Contact_Number
                          ? activeUser.Contact_Number
                          : ""
                      }
                      onChange={(event) => {
                        HandleFieldsValue(
                          event.target.name,
                          event.target.value
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-6 col-sm-12">
                  <div className="form-group">
                    <label className="form-label float-start mt-3">
                      Email Address:
                    </label>
                    <input
                      className="form-control"
                      type="email"
                      name="Email"
                      id="email"
                      placeholder="1234@gmail.com"
                      required="true"
                      defaultValue={activeUser.Email ? activeUser.Email : ""}
                      onChange={(event) => {
                        HandleFieldsValue(
                          event.target.name,
                          event.target.value
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <div className="form-group">
                    <label className="form-label float-start mt-3">
                      Website Address:
                    </label>
                    <input
                      className="form-control"
                      type="url"
                      id="wba"
                      placeholder="https://example.com"
                      pattern="https://.*"
                      size="30"
                      name="Website_address"
                      required="true"
                      defaultValue={
                        activeUser.Website_address
                          ? activeUser.Website_address
                          : ""
                      }
                      onChange={(event) => {
                        HandleFieldsValue(
                          event.target.name,
                          event.target.value
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-6 col-sm-12">
                  <div className="form-group">
                    <label className="form-label float-start mt-3">
                      Business Description
                    </label>
                    <textarea
                      class="form-control"
                      id="bd"
                      name="Business_description"
                      rows="1"
                      required="true"
                      defaultValue={
                        activeUser.Business_description
                          ? activeUser.Business_description
                          : ""
                      }
                      onChange={(event) => {
                        HandleFieldsValue(
                          event.target.name,
                          event.target.value
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  {/* <div className="form-group">
              <label className="form-label float-start mt-3">
                Password
              </label>
              <input
                type="password"
                class="form-control"
                id="pwd"
                name="pwd"
                //required="true"
                placeholder="Password"
                defaultValue={activeUser.Password?activeUser.Password:""}

                onChange={(event) =>{HandleFieldsValue( event.target.name,event.target.value)} }
              />
            </div> */}
                </div>
                <div className="col-6">
                  {/* <div className="form-group">
              <label className="form-label float-start mt-3">
                Confirm Password
              </label>
              <input
                type="password"
                class="form-control"
                id="cpwd"
                name="cpwd"
                //required="true"
                placeholder="Password"
                onChange={(event) =>{HandleFieldsValue( event.target.name,event.target.value)} }
              />
            </div> */}
                </div>

                <div className="row">
                  <div className="col-4"></div>
                  <div className="col-4 text-center">
                    <button
                      className="btn btn-md btn-primary mt-3 mb-5"
                      onClick={() => {
                        UpdateUser();
                      }}
                    >
                      Submit
                    </button>
                  </div>
                  <div className="col-4"></div>
                </div>
              </div>
            </div>
            <div className="col-2"></div>
          </div>
          {/* </form> */}
        </>
        {/* ))} */}
      </div>
    </>
  );
}

export default Edit;
