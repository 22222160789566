function Footer()
{
    return(
        <div class="container-fluid  bg-color pt-3 pb-3">
             <div class="row mt-5 ps-5">
             <div class="col-md-2 col-sm-12">
                <h4 class="foot-font">Links</h4>
                <ul>
                    <li className="mt-3"><a class="anchor3" href="https://www.drugtestpanels.com/collections/all">Products</a></li>
                    <li><a class="anchor3" href="https://www.ushealthtesting.com/pages/employment-drug-testing/">Lab Testing</a></li>
                    <li><a class="anchor3" href="https://www.drugtestpanels.com/#">Resources</a></li>
                    <li><a class="anchor3" href="https://www.drugtestpanels.com/pages/contact-us">Contact us</a></li>
                     </ul>
            </div>
            <div class="col-md-2 col-sm-12">
                <h4 class="foot-font">Products</h4>
                <ul>
                    <li  className="mt-3"><a class="anchor3" href="https://www.drugtestpanels.com/collections/urine-drug-testing-kits">Urine Drug Tests</a></li>
                    <li><a class="anchor3" href="https://www.drugtestpanels.com/pages/mouth-swab-drug-test">Mouth Swab Drug Tests</a></li>
                    <li><a class="anchor3" href="https://www.drugtestpanels.com/collections/hair-follicle-drug-test">Hair Follicle Drug Tests</a></li>
                    <li><a class="anchor3" href="https://www.drugtestpanels.com/collections/alcohol-breathalyzer">Alcohol Breathalyzer</a></li>
                     </ul>
            </div>
            <div class="col-md-2 col-sm-12">
                <h4 class="foot-font">Policy</h4>
                <ul>
                    <li  className="mt-3"><a class="anchor3" href="https://www.drugtestpanels.com/policies/privacy-policy">Privacy Policy</a></li>
                    <li><a class="anchor3" href="https://www.drugtestpanels.com/policies/refund-policy">Refund Policy</a></li>
                    <li><a class="anchor3" href="https://www.drugtestpanels.com/policies/terms-of-service">Terms of Service</a></li>
                    <li><a class="anchor3" href="https://www.drugtestpanels.com/policies/shipping-policy">Shipping Policy</a></li>
                    <li><a class="anchor3" href="https://www.drugtestpanels.com/pages/uritox-accepts-shiba-inu-cryptocurrency-payment">Shiba Inu Coin Policy</a></li>
                     </ul>
            </div>
            <div class="col-md-3 col-sm-12">
                <h4 class="foot-font">News Letter</h4>
                <p class="anchor3">Join the mailing list to receive the latest updates & best offers for Drug Test kits in Bulk.</p>

                
                    <input class="mt-3" type="email" placeholder="Your Email"/><br/>
                    <button type="button" class="btn btn-danger mt-2">SUBSCRIBE</button>
            </div>
            <div class="col-md-3 col-sm-12">
                <h4 class="foot-font">Contact us</h4>
                    <a class="anchor3" href="#">Toll Free: 877-487-4869</a>
                    <a class="anchor3" href="#">Uritox, LLC</a>
                    <a class="anchor3" href="#">2620 Centennial Rd STE S</a>
                    <a class="anchor3" href="#">Toledo, OH 43617</a>
                    <a class="anchor3" href="#">DUNS # 164998374 / CAGE #</a>
                    <a class="anchor3" href="#">51Q39</a>
            </div>
            {/* <div class="col-12 mt-5 p-5">
        <p class="fs-5 text-center Panels">Drug Test Panels conducts employment drug screening test through our 2100 labs nationwide in all the states at <a class="anchor3 text-danger" href="#">USHealthTesting.</a>  The lab drug test is available in AK, AL, AR, AZ, CA, CO, CT, DE, FL, GA, HI, IA, ID, IL, IN, KS, KY, LA, MA, MD, ME, MI, MN, MO, MS, MT, NC, ND, NE, NH, NJ, NM, NV, NY, OH, OK, OR, PA, RI, SC, SD, TN, TX, UT, VA, VT, WA, WI, WV and WY.</p>
    </div>

             </div>
             <div class="col-sm-12 col-md-12 col-lg-12 text-center pt-4 pb-4 px-5">
            <div class="text-dark ">
              <p>Copyright © 2023,<a href="#" class="text-decoration-none text-dark">Uritox, LLC.
                </a><a href="#" class="text-decoration-none text-dark"> Powered by Uritox
                    </a></p>
            </div> */}
          </div>
        </div>

    )
}
export default Footer;