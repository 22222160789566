import React from 'react'

 function Logout() {

    sessionStorage.removeItem("id");
    sessionStorage.removeItem("name");
  return (
    <div></div>
  )
}
export default Logout