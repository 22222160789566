import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Layout from "./components";
//import Addstory from "./components/Addstorydetails";
import Dashboard from "./components/Dashboard";
import Details from "./components/Details";
import Edit from "./components/Edit";
import Login from "./components/login";
import Registration from "./components/Registration/components/Registration";
//import SearchDetails from "./components/search/searchdetails";
import StateDetails from "./components/statedetails";
import Upload from "./components/Upload";
import Reset from "./components/ResetPassword"
import Logout from "./components/logout"
import EditPost from "./components/EditPost";
import GetAllPost from "./components/GetAllPost";

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Layout />}></Route>
          <Route path="/registration" element={<Registration />}></Route>
          <Route path="/login" element={<Login />} />
          <Route path="/resetpwd" element={<Reset />} />
          <Route path="/logout" element={<Logout />} />

          <Route path="dashboard" element={<Dashboard/>}>
            <Route path="" element={<Upload />} />
            <Route path="getuser/:id" element={<Edit />}></Route>
            <Route path="getStories" element={<GetAllPost  />}></Route>
            <Route path="getStories/:postid" element={<EditPost />}></Route>
          </Route>
          <Route path="/selected-centers/:id" element={<Details />} />

          <Route path="/state-details/:state" element={<StateDetails />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
