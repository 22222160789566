
import Footer from "./Footer";
import Homepage from "./Home";
import Topnav from "./Navbar/Topnav"

const Layout=()=>
{
return(
    <>
    <Topnav/>
    <div className="row">
        <div className="col-1">
        </div>
        <div className="col-md-10 col-sm-12" style={{minHeight:"100vh"}}>
          <Homepage/>
        </div>
        <div className="col-1">

        </div>
      </div>
    <Footer/>
    </>
)
}
export default Layout;