import React from 'react'
import axios from 'axios';
import { faCalendar, faCalendarDay, faCalendarDays, faCalendarWeek } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


function GetAllPost() {
    const[stories,setStories]=useState([]);
    var id = sessionStorage.getItem("id");

    const navigate = useNavigate();
    const setnav=(postid)=>
    {
     navigate("/dashboard/getStories/"+postid);

    }

    const getStories=async()=>
    {
      try{
        
         const data=await axios.get(`${process.env.REACT_APP_NODE_URL}/stories/stories/`+id);
         
         console.log(data.data,"stories");
         setStories(data.data);
         console.log(data.data[0].url)
         
      }
      catch(e)
      {
        console.log(e);
      }
    }
  
    
    useEffect(()=>
    {
       getStories();
       
    }, []);
  
    return (
      <>
  
        <div className="container  mb-5 ">
        <div className="row">
        <div className="col-2"></div>
        <div className="col-8">
            

        <h2 className="text-center mt-5 mb-5 text-color head-color">
Recovery Stories        </h2>
          </div>
          </div>
          {/* <h5 className="text-end"> <Link className="text-dark" to="">
            Add My Story
          </Link></h5> */}
  <div className="row row-cols-1 row-cols-sm-2 row-cols-md-6" >

        <div className="col-2"></div>

          {stories.map((names, index) => (
  
            <>
  
              {stories.length > 0 ? (
                  <>
               <div className="col" id="stories">
               <div class="card mt-3 card-shadow card-fixed">
              <img src={names.url} alt="Image Description" className="img-fixed"/>
              <div class="card-body "onClick={() =>setnav(names.center_id)}>
              <h5 class="card-title card-head">{names.title}</h5>
              <p class="card-text text-muted">{names.Business_name}</p>
              <p class="card-text">{names.author}</p>
              <p class="card-text">
              <FontAwesomeIcon icon={faCalendarDays} className="me-2"/>{names.date.split("T")[0]}</p>
              </div>
              </div>
              </div>
  </>
              ) : (
                ""
              )}
            </>
          ))}
          </div>
        </div>
        

      </>
    );
}

export default GetAllPost