import React from "react";
import { Link } from "react-router-dom";
import { faEdit,faUserEdit,faSignOut } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  CDBSidebar,
  CDBSidebarHeader,
  CDBSidebarMenuItem,
  CDBSidebarContent,
  CDBSidebarMenu,
  
  CDBSidebarSubMenu,
  CDBSidebarFooter,
} from 'cdbreact';

function Sidebar() {
  var id = sessionStorage.getItem("id");
  var name=sessionStorage.getItem("name");
  return (
    <>

      <CDBSidebar  backgroundColor="#fef9f8">
        <CDBSidebarHeader className="text-color" prefix={<i className="fa fa-bars text-color" />}>Welcome</CDBSidebarHeader>
        <CDBSidebarContent>
          <CDBSidebarMenu>
            
            <div className="col  text-start p-3">
               
               <Link className=" font" to={"/dashboard"}>
            <FontAwesomeIcon icon={faEdit} className="me-2"/>Add Your Stories
               </Link>
             </div>

                <div className="col text-start mt-5  p-3">
              <Link className=" font" to={"/dashboard/getuser/"+id}>
              <FontAwesomeIcon icon={faUserEdit} className="me-2"/>Edit profile{" "}
              </Link>

              
            </div>

            <div className="col text-start mt-5  p-3">
              <Link className=" font" to={"/dashboard/getStories"}>
              <FontAwesomeIcon icon={faUserEdit} className="me-2"/>Edit Stories{" "}
              </Link>

              
            </div>

            <div className="col text-start mt-5  p-3">

            <Link className=" font" onClick={"/logout"} to={"/"}>
              <FontAwesomeIcon icon={faSignOut} className="me-2"/>Logout{" "}
          </Link>
</div>
          </CDBSidebarMenu>
        </CDBSidebarContent>

        
      </CDBSidebar>      




        

      </>
  );
}
export default Sidebar;
