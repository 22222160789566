import axios from "axios";
const RegistrationHooks = (data) => {
  return new Promise((resolve) => {
    axios
      .post(`${process.env.REACT_APP_NODE_URL}/registration/signup`,data, {
        withCredentials: true,
      })
      .then((res) => {
        resolve(res.data);
        console.log(res.data, "hookres");
      })
      .catch((error) => {
        console.log(error);
      });
  });
};
export default RegistrationHooks;
