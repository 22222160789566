import React, { useState } from "react";
import axios from "axios";

function Upload() {
  const [post, setPost] = useState([]);
  const [url, setUrl] = useState();
  var id = sessionStorage.getItem("id");
  const HandlePost = (key, value) => {
    setPost({ ...post, [key]: value });
  };

  const PostUpload = () => {
    console.log(url,"serdtf");

    axios
      .post(`${process.env.REACT_APP_NODE_URL}/upload/upload/`,{
        title: post.title,
        author: post.author,
        content: post.content,
        url: url,
        id:id
      })
      .then((res) => console.log(res, "postres"))
      .catch((error) => {
        console.log(error);
      });
  };
  const ImgUpload = (key, value) => {
    let formData = new FormData(); //formdata object
    formData.append("folderPath", "/dtp");
    formData.append("filePermission", "private");
    formData.append("file", value);

    axios
      .post("http://localhost:4714/api/v1/s3/files/upload/", formData, {
        withCredentials: false,
        headers: { "content-type": "application/x-www-form-urlencoded" },
      })
      .then((res) => {
        console.log(res.data.data.data.Url, "formdata");
        setUrl(res.data.data.data.Url);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div className="container">
      {/* <form> */}
        <div className="row" style={{ minHeight: "100vh" }}>
          <div className="col-2"></div>
          <div className="col-8">
            <h2 className="text-center mt-5 mb-5 text-color">Add Your Stories</h2>
            <div className="form-group">
              <div className="row">
                <div className="col-12">

                  <label for="title" className="form-label float-start">
                    Blog Title
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="title"
                    onChange={(event) => {
                      HandlePost(event.target.name, event.target.value);
                    }}
                  />
                </div>

              </div>
              <div className="row">
                    <div className="col-12">

                        <label for="author" className='form-label float-start mt-4'>
                            Author Name
                        </label>
                        <input type="text"
                        name="author"
                        className='form-control'
                        onChange={(event) => {
                          HandlePost(event.target.name, event.target.value);
                        }} />
                    </div>

                </div>
                <div className="row">
                    <div className='col-12'>
                    {" "}
                        <label for="image" className='form-label float-start mt-4'>
                            Featured Image
                        </label>
                        <input type="file"
                        name="image"
                        className='form-control'
                        // value={url}
                        onChange={(event) => {
                          ImgUpload(event.target.name,event.target.files[0]);
                        }}/>
                    </div>
                    <div className="col-12"></div>
                </div>
              <div className="row">
                <div className="col-12">
                {" "}
                  <label for="content" className="form-label float-start mt-4">
                        Content
                    </label>
                  <textarea
                    rows="100"
                    cols="100"
                    name="content"
                    className="form-control"
                    onChange={(event) => {
                      HandlePost(event.target.name, event.target.value);
                    }}
                  ></textarea>
                </div>
                <div className="col-12"></div>
                </div>
              <div className="row">
                <div className="col-12">
                {" "}
                <button onClick={() => {
                        PostUpload();
                      }}  className="btn btn-md btn-primary mt-3 mb-3">Submit</button>
                </div>
                <div className="col-12"></div>
              </div>
            </div>
          </div>
          <div className="col-2"></div>
        </div>
      {/* </form> */}
    </div>
  )
}

export default Upload;
