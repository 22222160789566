import {
  faCalendar,
  faCalendarDay,
  faCalendarDays,
  faCalendarWeek,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useEffect, useState } from "react";

function Recentstories() {
  const [stories, setStories] = useState([]);
  const getStories = async () => {
    try {
      const data = await axios.get(
        `${process.env.REACT_APP_NODE_URL}/stories/recentstories`
      );
      console.log(data.data, "stories");
      setStories(data.data);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    getStories();
  }, []);
  return (
    <>
      <div className="container mt-5 mb-5 ">
        <h2 className="alert alert-primary alert-color">Recovery Stories</h2>
        {/* <h5 className="text-end"> <Link className="text-dark" to="">
          Add My Story
        </Link></h5> */}
        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-4">
          {stories.map((names, index) => (
            <>
              {stories.length > 0 ? (
                <>
                  <div className="col" id="stories">
                    <div class="card mt-3 card-shadow card-fixed">
                      <img
                        src={names.url}
                        alt="Image Description"
                        className="img-fixed"
                      />
                      <div class="card-body">
                        <h5 class="card-title card-head">{names.title}</h5>
                        <p class="card-text text-muted">
                          {names.Business_name}
                        </p>
                        <p class="card-text">{names.author}</p>
                        <p class="card-text">
                          <FontAwesomeIcon
                            icon={faCalendarDays}
                            className="me-2"
                          />
                          {names.date.split("T")[0]}
                        </p>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
            </>
          ))}
        </div>
      </div>
    </>
  );
}
export default Recentstories;
