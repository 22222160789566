import { Link } from "react-router-dom";
// import bg from "../Assets/Image/usht.png"
function Nav() {
  var name=sessionStorage.getItem("name");
  var id = sessionStorage.getItem("id");

  return (
    <div className="container-fluid ">
      <div className="row bg-color pt-3 pb-3 scroll-container">
      <div className="col-2"></div>
        <div className="col-8 col-sm-12 mt-3">
        
        </div>
        
        <div className="col-12 text-end  scroll-text">
          {/* <Link className="link-color" to="/">
            Logout{" "}
          </Link> */}
          <Link className="link-color " to={"/selected-centers/"+id}>
        {name}{" "}
          </Link>
        </div>
      </div>
    </div>
  );
}
export default Nav;
