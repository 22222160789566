

import Recentstories from "./Recentstories";

import DataTable from "./datatable";


function Homepage()
{


    return(
        <>
        <div className="container mb-5">
            <div className="row">
            </div>
            <Recentstories/>
             <DataTable/>

        </div>
        </>
    )
}
export default Homepage;