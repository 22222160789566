import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import bg1 from "../Assets/Image/u1.jpg";
function StateDetails(){

    const[statedet,setStatedet]=useState([]);
    const navigate = useNavigate();
   const{state}=useParams();

   const getSelectedstates = async () => {
    try {
      const data = await axios.get(
        `${process.env.REACT_APP_NODE_URL}/statedet/statedet/`+state
      );
      console.log(data.data, "getselcetedstate");
      setStatedet(data.data);
    } catch (e) {
      console.log(e);
    }
  };
  const setnav=(id)=>
  {
   navigate("/selected-centers/"+id);

  }
  useEffect(() => {
    getSelectedstates();
  }, []);
    return(
        <>
<div className="container mt-5">
        <h3 className="head-color">{statedet?.[0]?.State}</h3>
        <div className="row row-cols-2 row-cols-sm-2 row-cols-md-4">

        {statedet.map((names, index) => (

          <>

           {console.log(statedet.length,"center")}
            {statedet.length > 0 ? (
                <>
             <div className="col">
            <div class="card mt-3 card-shadow  card-fixed ">
            <img src={bg1} alt="Image Description"style={{height:"200px"}}/>
            <div class="card-body" onClick={() =>setnav(names.Business_id)}>
            <h5 class="card-title">{names.Business_name}</h5>
            <p class="card-text">{names.City},{names.State}</p>
            <p class="card-text">{names.Contact_no}</p>
            <p class="card-text">{names.Email}</p>
            </div>
            </div>
            </div>
</>
            ) : (
              ""
            )}
          </>
        ))}
        </div>
        </div>

        </>
    )
}
export default StateDetails;