import Footer from "./Footer";
import Topnav from "./Navbar/Topnav";
import bg1 from "../Assets/Image/u1.jpg";
import { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";
function Details() {
  const [selectcenter, setSelectCenter] = useState([]);
  const [details, setDetails] = useState([]);

  const [service, setService] = useState([]);
  const { id } = useParams();
  console.log(service);

  const getSelectedData = async () => {
    try {
      const data = await axios.get(
        `${process.env.REACT_APP_NODE_URL}/selected/selected/`+ id
      );
      console.log(data.data, "getdata");
      setSelectCenter(data.data);
    } catch (e) {
      console.log(e);
    }
  };

  const getData = async () => {
    try {
      const data = await axios.get(
        `${process.env.REACT_APP_NODE_URL}/selected/details/`+ id
      );
      setDetails(data.data);
      setService(data.data[0].Service.split(","));
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getSelectedData();
    getData();
  }, []);
  return (
    <>
      <Topnav />
      <div className="container" style={{ minHeight: "100vh" }}>
        <div className="row mt-5 box-shadow">
          {console.log(details, "dfvgbjh")}
          <>
            {details[0] !== undefined ? (
              <>
                <div className="col-md-1 col-sm-12 mt-4 mb-4"></div>
                <div className="col-md-11 col-sm-12 mb-4 ms-3">
                  <h3 className="mt-3 head-color head-desc">
                    {details.Business_name}
                  </h3>
                  <p className="desc-size">{details[0].Address1}</p>
                  <p className="desc-size">{details[0].Address2}</p>
                  <p className="desc-size">{details[0].State}</p>
                  <p className="desc-size">{details[0].Contact_no}</p>
                  <p className="desc-size">{details[0].Email}</p>
                </div>
              </>
            ) : (
              <></>
            )}

            <div className="col-md-2 col-sm-12"></div>
          </>
        </div>
        {details[0] !== undefined ? (
          <>
            <h4 className="head-color mt-4 ms-3 head-desc">
              Business Description
            </h4>
            <div>
              <p className="ms-3 desc-size pt-4">
                {details[0].Business_description}
              </p>
            </div>
          </>
        ) : (
          <></>
        )}
        <div className="container mt-5">
          <h4 className="head-color head-desc">Service Provided</h4>
          <div className="row row-cols-1 row-cols-sm-1 row-cols-md-4">
            <>
              {service !== undefined ? (
                service.map((center, index) => (
                  <>
                    <div className="col">
                      <button className="btn-service btn-blue btn rounded-pill mt-4">
                        {" "}
                        {center}
                      </button>
                    </div>
                  </>
                ))
              ) : (
                <></>
              )}
            </>
          </div>
        </div>

        <div className="container mt-5">
          <h2 className="head-color head-desc">Recent Stories</h2>
          <div className="row row-cols-1 row-cols-sm-2 row-cols-md-4">
            <>
              {selectcenter.map((center, index) => (
                <>
                  <>
                    <div className="col">
                      <div class="card mt-3 card-shadow  card-fixed mb-5">
                        <img
                          src={bg1}
                          alt="Image Description"
                          className="img-fixed"
                        />
                        <div class="card-body">
                          <h5 class="card-title card-head">{center.title}</h5>

                          <p class="card-text text-muted">{center.author}</p>
                          <p class="card-text">
                            {" "}
                            <FontAwesomeIcon
                              icon={faCalendarDays}
                              className="me-2"
                            />
                            {center.date.split("T")[0]}
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                </>
              ))}
            </>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default Details;
