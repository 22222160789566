
import axios from "axios";
import React, { useState } from "react";
import {Navigate, useNavigate} from "react-router-dom";
import Footer from "./Footer";
import AddNav from "./Navbar/AddNav";


const Login = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState({
    email: "",
    pass: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser({
      ...user,
      [name]: value,
    });
  };
  const userLogin = () => {

    console.log("Checking...........");
    const { email, pass } = user;
    console.log(user,"user");

    axios
    .post(`${process.env.REACT_APP_NODE_URL}/login/login`, { email, pass })
    .then((res) => {
        if(res.status===200)
        {
          console.log(res.data,"res")
          sessionStorage.setItem("id",res.data.data[0]["Business_id"]);
          sessionStorage.setItem("name",res.data.data[0]["Business_name"]);
          console.log(res.data.data[0].Business_name,"bname");
          alert("Login Sucessfully")
          navigate("/dashboard");
        }
       else{
        alert("Login Failed");
       }
      })
      .catch((error) => {
        console.log(error);
      });

  };
  return (
    <>
  <AddNav/>
    <div className="container">
      {/* <form> */}
        <div className="row" style={{ minHeight: "100vh" }}>
          <div className="col-2"></div>
          <div className="col-md-8 col-sm-12">
            <h2 className="text-center mt-5 mb-5 text-color head-color">Login</h2>
            <div className="form-group">
              <div className="row">
                <div className="col-md-4 col-sm-12"></div>
                  <div className="col-md-6 col-sm-12">
                  {" "}
                  <label for="email" className="form-label float-start">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control mb-3"
                    name="email"
                    value={user.email}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6 col-sm-12"></div>
              </div>
              <div className="row">
                <div className="col-md-4 col-sm-12"></div>
                  <div className="col-md-6 col-sm-12">
                  <label for="pass" className="form-label float-start">
                    Password
                  </label>
                  <input
                    type="password"
                    name="pass"
                    className="form-control mb-3"
                    value={user.pass}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6 col-sm-12"></div>
              </div>
              <div className="row">
                 <div className="col-md-4 col-sm-12"></div>
                <div className="col-md-3 col-sm-12 text-start">
                <button  onClick={() => {
                        userLogin();
                      }} className="btn btn-md btn-primary mt-3 mb-3 ">Login</button>
                </div>
                <div className="col-md-4 col-sm-12 m-3">
                <a  onClick={() => {
                        navigate("/resetPwd");
                      }} className=" text-end ">?Forgot Password</a>

                </div>
              </div>
            </div>
          </div>
          <div className="col-md-2 col-sm-12"></div>
        </div>
      {/* </form> */}
    </div>
    <Footer/>
    </>

  );
};

export default Login;

