import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../Footer";
import Nav from "../../Navbar/Nav";
import Topnav from "../../Navbar/Topnav";
import RegistrationHooks from "../hooks/useRegistration";

function Registration() {
  const [FieldValues, setFieldValues] = useState();
  const navigate = useNavigate();
  const HandleFieldsValue = (key, value) => {
    setFieldValues({ ...FieldValues, [key]: value });

  };
  // const Login = (event) => {
  //   console.log("====================================");
  //   console.log(FieldValues, "FieldValues");
  //   console.log("====================================");

  //   if (FieldValues.pwd === FieldValues.cpwd) {
  //     axios.post("http://localhost:3005/registration/signup", FieldValues)
  //     .then((res) =>{ console.log(res,"res");
  //       alert("Registered Successfully");
  //        navigate("/login");
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  //   } else {
  //       alert("Password Doesn't match");
  //   }
  // };
  const Login=async(event)=>{
    if (FieldValues.pwd === FieldValues.cpwd)
    {
      const res=await RegistrationHooks(FieldValues)
      if(res.status==200)
      {
        console.log(res,"res");
        alert("Registered Sucessfully");
        navigate("/login");
      }
    }
    else{
      alert("Password Doesn't Match");
    }
  }
  return (
    <>

      <div className="container">
        {/* <form> */}
          <div className="row" style={{ minHeight: "100vh" }}>
            <div className="col-md-2 col-sm-12"></div>
            <div className="col-md-8 col-sm-12">
              <h2 className="text-center mt-5 mb-5 text-color head-color">
                Add your Business
              </h2>
              <div className="form-group">
                <label className="form-label float-start">Business Name:</label>
                <input
                  className="form-control"
                  placeholder="Enter your Business Name"
                  type="text"
                  name="bname"
                  id="bname"
                  required="true"
                  onChange={(event) => {
                    HandleFieldsValue(event.target.name, event.target.value);
                  }}
                />
              </div>
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <div className="form-group">
                    <label className="form-label float-start mt-3">
                      Address Line 1:
                    </label>
                    <input
                      className="form-control"
                      placeholder="Enter your Address"
                      type="text"
                      name="al1"
                      id="al1"
                      required="true"
                      onChange={(event) => {
                        HandleFieldsValue(
                          event.target.name,
                          event.target.value
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-6 col-sm-12">
                  <div className="form-group">
                    <label className="form-label float-start mt-3">
                      Address Line 2:
                    </label>
                    <input
                      className="form-control"
                      placeholder="Enter your Address"
                      type="text"
                      name="al2"
                      id="al2"
                      onChange={(event) => {
                        HandleFieldsValue(
                          event.target.name,
                          event.target.value
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <div className="form-group">
                    <label className="form-label float-start mt-3">City:</label>
                    <input
                      className="form-control"
                      placeholder="Enter your city"
                      type="text"
                      name="city"
                      id="city"
                      required="true"
                      onChange={(event) => {
                        HandleFieldsValue(
                          event.target.name,
                          event.target.value
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-6 col-sm-12">
                  <div className="form-group">
                    <label className="form-label float-start mt-3">State</label>
                    <input
                      className="form-control"
                      placeholder="Enter your state"
                      type="text"
                      name="state"
                      id="state"
                      required="true"
                      onChange={(event) => {
                        HandleFieldsValue(
                          event.target.name,
                          event.target.value
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <div className="form-group">
                    <label className="form-label float-start mt-3">
                      Country:
                    </label>
                    <input
                      className="form-control"
                      placeholder="Enter your Country"
                      type="text"
                      name="country"
                      id="country"
                      required="true"
                      onChange={(event) => {
                        HandleFieldsValue(
                          event.target.name,
                          event.target.value
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-6 col-sm-12">
                  <div className="form-group">
                    <label className="form-label float-start mt-3">
                      Postal Code:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Postal code"
                      pattern="[0-9]*"
                      required="true"
                      name="pcode"
                      id="pcode"
                      onChange={(event) => {
                        HandleFieldsValue(
                          event.target.name,
                          event.target.value
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <div className="form-group">
                    <label className="form-label float-start mt-3">
                      Contact No.
                    </label>
                    <input
                      type="tel"
                      id="phone"
                      className="form-control"
                      name="phone"
                      placeholder="123-45-678"
                      pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                      required="true"
                      onChange={(event) => {
                        HandleFieldsValue(
                          event.target.name,
                          event.target.value
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-6 col-sm-12">
                  <div className="form-group">
                    <label className="form-label float-start mt-3">
                      Email Address:
                    </label>
                    <input
                      className="form-control"
                      type="email"
                      name="email"
                      id="email"
                      placeholder="1234@gmail.com"
                      required="true"
                      onChange={(event) => {
                        HandleFieldsValue(
                          event.target.name,
                          event.target.value
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <div className="form-group">
                    <label className="form-label float-start mt-3">
                      Website Address:
                    </label>
                    <input
                      className="form-control"
                      type="url"
                      id="wba"
                      placeholder="https://example.com"
                      pattern="https://.*"
                      size="30"
                      name="wba"
                      required="true"
                      onChange={(event) => {
                        HandleFieldsValue(
                          event.target.name,
                          event.target.value
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-6 col-sm-12">
                  <div className="form-group">
                    <label className="form-label float-start mt-3">
                      Business Description
                    </label>
                    <textarea
                      class="form-control"
                      id="bd"
                      name="bd"
                      rows="1"
                      required="true"
                      onChange={(event) => {
                        HandleFieldsValue(
                          event.target.name,
                          event.target.value
                        );
                      }}
                    />
                  </div>
                </div>

                {/* <div className="row">
                  <div className="col-4"></div>
                  <div className="col-4 text-center">
                    <button
                      type="submit"
                      className="btn btn-md btn-primary mt-3 mb-3"
                      onClick={() => {Login() }}
                    >
                      Submit
                    </button>
                  </div>
                  <div className="col-4"></div>
                </div> */}
              </div>
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <div className="form-group">
                    <label className="form-label float-start mt-3">
                      Password
                    </label>
                    <input
                      type="password"
                      class="form-control"
                      id="pwd"
                      name="pwd"
                      //required="true"
                      placeholder="Password"
                      onChange={(event) => {
                        HandleFieldsValue(
                          event.target.name,
                          event.target.value
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-6 col-sm-12">
                  <div className="form-group">
                    <label className="form-label float-start mt-3">
                      Confirm Password
                    </label>
                    <input
                      type="password"
                      class="form-control"
                      id="cpwd"
                      name="cpwd"
                      //required="true"
                      placeholder="Password"
                      onChange={(event) => {
                        HandleFieldsValue(
                          event.target.name,
                          event.target.value
                        );
                      }}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-4 col-sm-12"></div>
                  <div className="col-md-4 col-sm-12 text-center">
                    <button
                      className="btn btn-md btn-primary mt-3 mb-3"
                      onClick={() => {
                        Login();
                      }}
                    >
                      Submit
                    </button>
                  </div>
                  <div className="col-4"></div>
                </div>
              </div>
            </div>
            <div className="col-2"></div>
          </div>
        {/* </form> */}
      </div>
      <Footer/>
    </>
  );
}
export default Registration;
