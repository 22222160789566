import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import axios from 'axios';


 function EditPost() {
const [post,setPost]=useState([])
// const [updatedpost,setUpdatedpost]=useState([])
const [fileData, setFileData] = useState(null);
const [url, setUrl] = useState();


  const{postid}=useParams()
  const HandlePost = (key, value) => {
    const field = { ...post };
    console.log(key,value);
      field[key]=value;
    console.log(field,"field");
    setPost(field);
    };


  

const getSelectedPost = async() =>{
  try{
    
    const data=await axios.get(`${process.env.REACT_APP_NODE_URL}/stories/selectedStories/`+postid);
    console.log(data.data);
  setPost(data.data[0])
  }catch(err){
  console.log(err);
}
}


const ImgUpload = (key, value) => {
  let formData = new FormData(); //formdata object
  formData.append("folderPath", "/dtp");
  formData.append("filePermission", "public-read");
  formData.append("file", value);


  axios
    .post("http://localhost:4714/api/v1/s3/files/upload/", formData, {
      withCredentials: false,
      headers: { "content-type": "application/x-www-form-urlencoded" },
    })
    .then((res) => {
      console.log(res.data.data.data.Key, "formdata");
      console.log(res.data.data, "formdata");
      setFileData(res.data.data);
      setUrl(res.data.data.data.Url);

    })
    .catch((error) => {
      console.log(error);
    });
};


const PostUpload =async()=>{
    
  await axios
 .put(`${process.env.REACT_APP_NODE_URL}/stories/uploadpost/`+postid,{title: post.title,
 author: post.author,
 content: post.content,
 url: url,
})
 .then((response) => {
  // setUpdatedpost(response.data);
   console.log(response.data);
   alert("updated Sucessfully");
 })
 .catch((err) => {
   console.log(err);
 });
}


useEffect(()=>{
  getSelectedPost();
}, [])

return (
    <div className="container">
      {/* <form> */}
        <div className="row" style={{ minHeight: "100vh" }}>
          <div className="col-2"></div>
          <div className="col-8">
            <h2 className="text-center mt-5 mb-5 text-color">Edit Your Stories</h2>
            <div className="form-group">
              <div className="row">
                <div className="col-12">

                  <label for="title" className="form-label float-start">
                    Blog Title
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="title"
                    defaultValue={post.title?post.title:""}

                    onChange={(event) => {
                      HandlePost(event.target.name, event.target.value);
                    }}
                  />
                </div>

              </div>
              <div className="row">
                    <div className="col-12">

                        <label for="author" className='form-label float-start mt-4'>
                            Author Name
                        </label>
                        <input type="text"
                        name="author"
                        className='form-control'
                        defaultValue={post.author?post.author:""}

                        onChange={(event) => {
                          HandlePost(event.target.name, event.target.value);
                        }} 
                        />
                    </div>

                </div>
                <div className="row">
                    <div className='col-12'>
                    {" "}
                        <label for="image" className='form-label float-start mt-4'>
                            Featured Image
                        </label>
                        <input type="file"
                        name="image"
                        className='form-control'
                        defaultValue={post.url?post.url:""}
                        // value={url}
                        onChange={(event) => {
                          ImgUpload(event.target.name,event.target.files[0]);
                        }}
                        />
                    </div>
                    <div className="col-12"></div>
                </div>
              <div className="row">
                <div className="col-12">
                {" "}
                  <label for="content" className="form-label float-start mt-4">
                        Content
                    </label>
                  <textarea
                    rows="100"
                    cols="100"
                    name="content"
                    className="form-control"
                    defaultValue={post.content?post.content:""}

                    onChange={(event) => {
                      HandlePost(event.target.name, event.target.value);
                    }}
                  ></textarea>
                </div>
                <div className="col-12"></div>
                </div>
              <div className="row">
                <div className="col-12">
                {" "}
                <button 
                onClick={() => {
                        PostUpload();
                      }}  
                      
                      className="btn btn-md btn-primary mt-3 mb-3">Update</button>
                </div>
                <div className="col-12"></div>

                
              </div>
            </div>
          </div>
          <div className="col-2"></div>
        </div>
      {/* </form> */}
    </div>
  )
  
}
export default EditPost;