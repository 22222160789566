import React, { useEffect, useState } from "react";
import axios from "axios";
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import { useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';

function Datatable(props) {
    const navigate = useNavigate();

     const [center, setCenter] = useState([]);
          


     const getData=async()=>
    {
           try{
           
            const data=await axios.get(`${process.env.REACT_APP_NODE_URL}/centers/centers`);
              console.log(data.data,"data");
              setCenter(data.data);

           }
           catch(e)
           {
            console.log(e);
           }
    }
    useEffect(()=>{
        getData();

    },[] )


    

const columns = [
    {
        name: 'BusinessName',
        selector: row => row.Business_name,
        width:"350px !important",
    },
    {
        name: 'Address',
        selector: row => row.Address1,
    },
    
    {
        name: 'City',
        selector: row => row.City,
    },
    {
        name: 'State',
        selector: row => row.State,
    },
    {
        name: 'Contact',
        selector: row => row.Contact_Number,
    },
    {
        name: 'Postal code',
        selector: row => row.Postal_code,
    },
];

 
    



return (

        <>
<div className="table-container">
<h2 className="alert alert-primary alert-color">Explore Centers</h2>

        <DataTableExtensions
         filterPlaceholder="Search Here"
          columns={columns}
          data={center}
          print={false}
          export={false}
          
        >
           
          <DataTable
             onRowClicked={center => (
                navigate(`${"/selected-centers/"+center.Business_id}`)

              )}
            noHeader
           pagination
            highlightOnHover

          />
        </DataTableExtensions>

        
         
		</div>

        </>
  );

                
}


export default Datatable